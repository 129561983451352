var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pa-10",attrs:{"id":"page-content"}},[_c('v-card',{staticClass:"base-card base-card-primary pa-5",attrs:{"elevation":"0","loading":_vm.loading}},[_c('v-card-title',[_c('h4',[_vm._v(" Report Preview:"),_c('br'),_c('span',{staticStyle:{"font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.report && _vm.report.name)+" - Session: "+_vm._s(_vm.sessionName)+" ")])]),_c('v-spacer'),_c('v-btn',{staticClass:"btn-link gtr-color-gray",attrs:{"link":"","text":"","ripple":false,"href":_vm.reportSnapshotResultReport && _vm.reportSnapshotResultReport.url}},[_c('v-icon',{staticStyle:{"margin-right":"8px"}},[_vm._v("mdi-file-download-outline")]),_c('span',{staticClass:"gtr-text-capitalize"},[_vm._v("Export")])],1),(_vm.$hasPermission('track.attendance_tracking_reports.edit'))?_c('v-btn',{staticClass:"btn-link gtr-color-gray",attrs:{"link":"","text":"","ripple":false,"to":{
          name: 'level-two.modules.track.reports.edit',
          params: _vm.$route.params
        }}},[_c('v-icon',{staticStyle:{"margin-right":"8px"}},[_vm._v("mdi-pencil-outline")]),_c('span',{staticClass:"gtr-text-capitalize"},[_vm._v("Edit")])],1):_vm._e()],1),_c('v-card-text',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[(_vm.reportSnapshotResultReport &&
                _vm.reportSnapshotResultReport.report &&
                _vm.$hasPermission('general.participants.view'))?_c('th',{attrs:{"scope":"col"}},[_vm._v("View")]):_vm._e(),_vm._l((_vm.reportSnapshotResultReport.export_headings),function(item,key){return _c('th',{key:key,attrs:{"id":key}},[_vm._v(" "+_vm._s(item)+" ")])})],2)]),_vm._l((_vm.reportPages),function(pageRows,pageNumber){return _c('tbody',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentReportPage == pageNumber),expression:"currentReportPage == pageNumber"}],key:pageNumber,staticClass:"report-preview"},_vm._l((pageRows),function(rowData,rowIndex){return _c('tr',{key:rowIndex},[(_vm.reportSnapshotResultReport && _vm.$hasPermission('general.participants.view'))?_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"ripple":false,"to":{
                        path: ':event_uuid/attendees/:attendee_uuid/edit',
                        name: 'level-two.event.attendees.edit',
                        params: {
                          event_uuid: _vm.event_uuid,
                          attendee_uuid: _vm.attendeeUuids[rowIndex].uuid
                        }
                      },"depressed":"","icon":"","target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v("Click to see this participants record")])])],1):_vm._e(),_vm._l((rowData),function(item,cellIndex){return _c('td',{key:cellIndex},[(isNaN(item) && (new Date(item) !== 'Invalid Date') && !isNaN(new Date(item)))?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatReportDateTimeLocal24Hr")(item,_vm.eventTimeZone))+" ")]):_c('span',[_vm._v(" "+_vm._s(item)+" ")])])})],2)}),0)})]},proxy:true}])}),(_vm.numberOfReportPages > 1)?_c('v-container',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"priority-default",on:{"click":_vm.prevReportPage}},[_vm._v("Prev")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_vm._v(" Page "),_c('v-select',{staticClass:"pagePicker",attrs:{"outlined":"","items":_vm.reportPageNumbers,"value":_vm.currentReportPage,"hide-details":true},model:{value:(_vm.currentReportPage),callback:function ($$v) {_vm.currentReportPage=$$v},expression:"currentReportPage"}}),_vm._v(" of "+_vm._s(_vm.numberOfReportPages)+" ")],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"priority-default",on:{"click":_vm.nextReportPage}},[_vm._v("Next")])],1)],1)],1):_vm._e()],1)],1),_c('gtr-modal',{attrs:{"value":_vm.loading,"closable":false,"title":"Loading"}},[_c('div',{staticClass:"text-center"},[_c('v-icon',{staticStyle:{"font-size":"1000%"},attrs:{"color":"var(--teal-200)"}},[_vm._v("mdi-file-document-outline")]),_c('h2',[_vm._v("Loading Report")]),_c('p',[_vm._v(" Loading Report. Please note, this process can take several minutes ")]),_c('v-progress-circular',{attrs:{"indeterminate":"","size":64}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }