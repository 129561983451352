import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'

@Component({
  name: 'GtrTrackReportDetailsView',
  computed: {
    ...mapState('report', ['reportSnapshot', 'reportSnapshotResult', 'reportSnapshotResultData']),
    ...mapState('sessions', ['sessions'])
  }
})
export default class GtrTrackReportDetailsView extends Vue {
  loading = true;
  sessions!: Array<Record<string, any>>;
  reportSnapshotResultReport: Record<string, any> = {};
  reportSnapshot!: Record<string, any>;
  reportSnapshotResult!: Record<string, any>;
  event_uuid = this.$route.params.event_uuid;
  report_uuid = this.$route.params.report_uuid;
  rowsPerPage = 100;
  currentReportPage = 1;
  export_data = [];

  // before the component gets mounted clean out report data.
  async created () {
    this.$store.commit('report/SET_REPORT_SNAPSHOT', {})
    this.$store.commit('report/SET_REPORT_SNAPSHOT_RESULT', {})
  }

  async mounted () {
    this.reportSnapshotResultReport = {}
    this.loading = true
    await this.fetchData()
  }

  get report () {
    return this.reportSnapshotResultReport?.report
  }

  get attendeeUuids () {
    return this.reportSnapshotResultReport.row_to_uuid_map
  }

  get sessionName (): string {
    if (this.report?.session_id) {
      return this.sessions?.[this.report.session_id - 1]?.name
    }
    return 'unknown'
  }

  get eventTimeZone (): string {
    return this.$store.state.event.event.timezone
  }

  @Watch('reportSnapshotResultData', { immediate: true })
  async onReportSnapshotResultDataChange (payload: any) {
    if (payload) {
      this.export_data = payload
    }
  }

  /**
   * Returns an object whose indexes are page numbers and whose values are
   * objects whose indexes are the original row index and whose values are the row data.
   */
  get reportPages () {
    const data = this.export_data
    const pages: any = {}
    if (Array.isArray(data) && data.length) {
      let page = 1
      pages[page] = {}
      for (let i = 0; i < data.length; i++) {
        pages[page][i] = data[i]
        if (Math.ceil((i + 1) % this.rowsPerPage) === 0) {
          pages[++page] = {}
        }
      }
    }
    return pages
  }

  get numberOfReportPages (): number {
    if (Array.isArray(this?.export_data)) {
      return Math.ceil(this.export_data.length / this.rowsPerPage)
    }
    return 0
  }

  get reportPageNumbers (): any[] {
    const pageNumbers: any[] = []
    for (let p = 1; p <= this.numberOfReportPages; p++) {
      pageNumbers.push({ text: p.toString(), value: p })
    }
    return pageNumbers
  }

  prevReportPage () {
    if (this.currentReportPage === 1) {
      this.currentReportPage = this.numberOfReportPages
    } else {
      this.currentReportPage--
    }
  }

  nextReportPage () {
    if (this.currentReportPage === this.numberOfReportPages) {
      this.currentReportPage = 1
    } else {
      this.currentReportPage++
    }
  }

  @Watch('reportSnapshotResult', { immediate: true })
  onReportSnapshotResultChange (payload: any) {
    if (payload) {
      if (payload.status === 'COMPLETE' || payload.status === 'FAILED') {
        this.loading = false
      }
      if (payload.status === 'FAILED') {
        Container.get(Notification).error('There was an error loading the report, please contact support')
      }
      this.reportSnapshotResultReport = payload
    }
  }

  private async fetchData () {
    await this.$store.dispatch('report/getReportSnapshot', { event_uuid: this.event_uuid, report_uuid: this.report_uuid })
    await this.$store.dispatch('sessions/fetchSessions', { event_uuid: this.event_uuid })
  }
}
